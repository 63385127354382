.title {
    color: $brandcolor;
    font-weight: normal;
}
.advantages {
    list-style-position: outside;
    list-style-type: disc;
    padding: 0 0 0 1.5rem;

    li {
        font-size: 1rem;
        margin: 1rem 0;
    }
}
.initialization {
    @extend %box;
    display: block;
    font-family: monospace;
    margin: 0 auto 1rem;
}
.options {
    font-size: 1rem;
    display: block; // iOS displays as table by default. Interferes with overflow on small screens.
    overflow-x: auto;
}
.filler p {
    color: $fadetext;
}