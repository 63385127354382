$offwhite : #e6eced;
$blackish : #052933;
$border : #777;
$accent : rgba(white, 0.6);

$accenttext : #2db6dd;
$linktext : #0f7895;
$fadetext : rgba($blackish, 0.2);

$tableBackground : $offwhite;
$bodyBackground : white;
$tableBackgroundAccent : rgba($accent, 0.5);

$brandcolor : rgba(200,0,123, 1);

html {
   background: $offwhite;
   color: $blackish;
}