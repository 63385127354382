%linkbutton {
	border: 1px solid $linktext;
	border-radius: 0.5rem;
	display: inline-block;
	color : $linktext;
	font-weight: 500;
	padding: 0.25rem 0.5rem;
	text-decoration: none;
	transition: background-color 0.1s, color 0.1s;

	&:hover {
		background-color: $linktext;
		color : $offwhite;
	}
}

%highlightcode {
	color: $brandcolor;
}

%box {
    background: $accent;
    border-radius: 0.5rem;
    padding: 1rem;
    
    overflow-x: scroll;

    .callout {
        @extend %highlightcode;
    }
}

