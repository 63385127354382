// shamelessly ripped / remixed from https://github.com/jlong/sass-twitter-bootstrap

//
// Tables
// --------------------------------------------------
$baseLineHeight : 1.4;
$baseBorderRadius : 0.5rem;


// BASE TABLES
// -----------------

table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

// BASELINE STYLES
// ---------------

.table {
  width: 100%;
  // Cells
  th,
  td {
    padding: 0.75rem;
    line-height: $baseLineHeight;
    text-align: left;
    vertical-align: top;
  }
  th {
    font-weight: bold;
  }
  
  // Bottom align for column headings
  thead th {
    vertical-align: bottom;
  }
  // Remove top border from thead by default
  caption + thead tr:first-child th,
  caption + thead tr:first-child td,
  colgroup + thead tr:first-child th,
  colgroup + thead tr:first-child td,
  thead:first-child tr:first-child th,
  thead:first-child tr:first-child td {
    border-top: 0;
  }
  // Account for multiple tbody instances
  tbody + tbody {
  }
}

// ZEBRA-STRIPING
// --------------

// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  tbody {
    > tr:nth-child(odd) > td,
    > tr:nth-child(odd) > th {
      background-color: $tableBackgroundAccent;
    }
  }
}

// TABLE CELL SIZING
// -----------------

// Reset default grid behavior
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none; // undo default grid column styles
  margin-left: 0; // undo default grid column styles
}

// Custom
th.subhead {
    font-size: 0.75rem;
    font-weight: normal;
    padding-top: 1.5rem;
    text-transform: lowercase;

  }

  td .code {
    @extend %highlightcode;
  }

