@include font-face(OpenSans, '../fonts/OpenSans-Regular-webfont');
@include font-face(OpenSans, '../fonts/OpenSans-Bold-webfont', bold);

body {
    font-size: 1rem;
    font-family: 'OpenSans';
    line-height: 1.625;
}

h1 {
    font-size: 2rem;
    margin: 0 0 1rem;
}
h2 {
    font-size: 1.3rem;
    margin: 0 0 1rem;
}
h3 {
    font-size: 1.2rem;
    margin: 0;
}
p {
    font-size: 1rem;
}
h1, h2, h3 {
    font-weight: bold;
} 
p {
	margin: 1rem 0;
    
    &:last-child {
       margin: 0;
    }
}
pre {
    font-family: 'Inconsolata', monospace;
}
strong {
    font-weight: bold;
}
em {
    font-style: italic;
}
p, li {
    max-width: 685px;
}